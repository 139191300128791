.swiper{

  &-home{

    .btn{
      padding: 22px 17px;
      color: $white;

      &:hover{
        color: $black;
      }
    }

    &.swiper-viewport{
      border: none;
      border-radius: 0;
      box-shadow: $wshadow;
      margin-bottom: 60px;
    }


    &-item{
      width: 100%;
      height: 590px;
    }

    &-text{
      height: 100%;

      &-block{

      }
    }
    
    &-title{
      font-size: 48px;
      font-weight: 900;
      color: $white;
      margin-bottom: 30px;
      
      &:first-letter{
        color: $orange;
      }
    }

    .swiper-container-horizontal>.swiper-pagination-bullets{
      bottom: 40px;
    }
    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      background: transparent;
      border: solid 1px $grey;
      box-shadow: none;

      &:hover{
        background: $white;
      }
    }

    .swiper-pagination-bullet-active {
      background: $white;
    }
  }
}
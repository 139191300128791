.faqs{
  background: linear-gradient(to right,$orange 50%,$white 50%);
  padding: 60px 0 120px;

  .title{
    margin-bottom: 30px;
  }

  &-accordion{

    .h3{
      padding: 23px 35px 23px 30px;
      cursor: pointer;
      margin-bottom: 0;
      font-size: 16px;
      position: relative;
      display: block;
      font-weight: 700;

      &:after,&:before{
        position: absolute;
        right: 30px;
        content: ' ';
        height: 22px;
        width: 1px;
        background-color: #333;
        transition: transform .3s;
        top: calc(50% - 11px);
      }

      &:not(.collapsed){
        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }

      &:before {
        transform: rotate(90deg);
      }

      &:after {
        transform: rotate(0deg);
      }

    }

    &-item{
      background: $white;
      margin-bottom: 15px;
    }

    &-text{
      padding: 10px 30px;
      p{
        margin-bottom: 0;
      }
      a{
        text-decoration: underline;
      }
    }
  }

  &-reviews{
    padding-left: 55px;

    &-item{
      padding: 35px 50px;
      margin-bottom: 10px;
      background: $lgrey;
      border: solid rgba($grey,.3) 1px;
    }

    &-title{
      font-weight: bold;
    }

    &-rating{

      svg{
        width: 15px;
        height: 15px;
        margin-right: 2px;
        fill: $grey;
      }

      &-active{
        fill: $orange!important;
      }
    }

    &-text{
      margin-bottom: 15px;
      margin-top: 15px;
    }

    &-date{
      color: $grey;
      margin-right: 40px;
    }
  }
}
.category{

  .products{
    margin-bottom: 60px;
  }

  .btn{
    width: 100%;
    padding: 13px 0;
  }

  .title{
    margin-bottom: 30px;
  }

  &-block{
    margin:0 -5px;
    margin-bottom: 60px;
  }

  &-item{
    flex: 0 0 20%;
    max-width: 20%;
    padding: 5px;
    margin-bottom: 10px;
  }

  &-content{
    padding: 20px;
    background: $white;
    border: solid 1px $mgrey;
    height: 100%;


    &:hover{
      box-shadow: $shadow;
    }
  }

  &-name{
    font-weight: bold;
    margin-bottom: 20px;
  }

}
@media all and (max-width: 1499.5px) {

  .header-phones{
    padding-left: 0;
  }
  .header-menu-body .child li {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .header-menu-body .child {
    right: calc(-192% - 90px);
    width: calc(192% + 90px);
  }
  .header-menu-body .child li:nth-child(even) {
    background: $white;
  }
  .header-menu-body .child li:nth-child(odd) {
    background: $lgrey;
  }

  .feedback-title-block{
    flex: 0 0 100%;
    text-align: center;
  }

  .feedback {
     padding: 40px 0 110px;
  }

  p.feedback-text{
    margin-bottom: 15px;
  }
  .category-item {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .product .gallery-thumbs{
    height: 370px;
  }

  .product-tabs .tab-content {
    padding: 0 30px;
  }

  .product-quick,.product-cart {
    padding: 14px 18px;
  }

  .product-gallery-show {
    bottom: 90px;
  }

  .product-top {
    margin-bottom: 30px;
  }
  .header-account button,.header-menu-title {
    padding: 15px 30px;
  }

  .header-lang .language-selected {
    padding: 15px 30px;
  }
  .header-bottom-block {
    height: 50px;
  }
  .header-phones .viber {
    font-size: 18px;
  }

  .header-phones {

    .header-email{
      padding-left: 0;
    }
  }

  .header-phones .viber svg {
    width: 20px;
    height: 20px;
  }

  .header-phones {

    .dropdown{
      text-align: left;
    }
  }

  .header-email svg {
    margin-right: 5px;
    margin-left: 5px;
  }

  .header-phones .dropdown:after {
    right: 18px;
    top: -2px;
  }

  .header-top-callback {
    padding: 13px 10px;

    &.btn{
      font-size: 12px;
    }
  }

  .header-top ul li {
    padding-left: 25px;
  }

  .product-options .btn {
    padding: 13px 10px;
  }

  .product-options input, .product-options select {
    padding: 5px 15px;
  }

  .product-tabs .nav-tabs {
    font-size: 18px;
  }

  .product-cart {
    background: #fff;
  }

  .product-quick,.product-cart{
    height: 51px;
  }

  .header-informations .dropdown {
    width: 100px;
  }


  ///1499
}

@media all and (max-width: 1199.5px) {

  #logo{
    margin-left: 30px;
  }

  .header-top {
    padding: 15px 0;
  }

  #mobile-search{
    margin-right: 15px;
    cursor: pointer;

    svg{
      width: 25px;
      height: 25px;
    }
  }

  .header-search{
    display: none;
  }

  .mobile-lang{

    .header-lang{
      border-right:none;
      border-left:none;
      .language-selected {
        padding: 0 3px;
        border: none;
        background: transparent;
        margin-right: 10px;
      }
      .language-select{
        border: none;
        background: white;
        padding: 15px 15px;
        box-shadow: $shadow;
      }
    }

    .header-lang .dropdown-menu.show{
      margin-left: 15px;
    }
  }

  .feedback input {
    height: 50px;
    width: 230px;
  }
  .feedback .btn-black {
    padding: 13px 75px;
  }

  .feedback-download button {
    margin-top: 23px;
  }

  .faqs-reviews {
    padding-left: 0;
  }

  .faqs-reviews-item {
    padding: 15px 20px;
  }
  .faqs {
    background: linear-gradient(to right,#ffae00 58%,#fff 42%);
    padding: 30px 0 75px;
  }
  .about{
    position: relative;

    .col-xl-4{
      position: absolute;
    }
  }
  .about-image{
    position: absolute;
    top: 0;
    right: 80px;
    opacity: .2;
  }
  .category-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .product-gallery-show {
    bottom: 0;
    top: 25px;
  }

  .product .gallery-thumbs {
    height: 485px;
  }

  .product-images {
    max-height: 498px;
  }

  .cart{
    .simplecheckout-left-column {
      width: 100%;
      margin-right: 0;
    }
    .simplecheckout-right-column {
      width: 100%;
      float: none;
    }


  }

  .account-register{
    .simple-content {
      margin: 0 0;
    }
  }

  #column-right{
    margin-top: 15px;
  }

  .account .simpleregister .form-horizontal .form-group {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .account-register .title {
    margin: 0 0;
  }

  #cartDropdown{
    display: none;
  }

  //1199
}

@media all and (max-width: 991.5px) {



  .feedback input {
    width: 100%;
    margin-bottom: 10px;
  }

  .advantages-block {
    padding: 20px;
  }

  .feedback-form {
    margin: auto;
  }

  .feedback-download button {
    margin-top: 5px;
    margin-bottom: 15px;
    width: 100%;
  }

  .news-href {
    margin-top: 0;
  }

  .title, .faqs .title, .news .title,.popular .title {
    margin-bottom: 15px;
  }

  .modules, .modules-product,.popular,.swiper-home.swiper-viewport,.advantages,.catalog,.news,.product-block {
    margin-bottom: 30px;
  }


  .faqs {
    background: $orange;
  }

  .about {

    padding: 30px 30px;
    margin-top: -40px;
    margin-bottom: 30px;
  }

  .product-block {
    padding: 15px;
  }

  .breadcrumb{
    display: flex;
    overflow-x: scroll;
    margin-bottom: 10px;

    li{
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }
  .product-faq {
    margin-bottom: 30px;
    padding: 30px;
  }


  //991
}

@media all and (max-width: 767.5px) {

  #logo{
    width: 150px;
  }

  .header-top {
    padding: 5px 0;
  }

  .feedback-form {
    margin: auto;
    width: 100%;
  }

  .catalog {
    padding: 30px;
  }

  .catalog-container{
    display: block;
    max-height: 100%;
  }
  .faqs-accordion h3 {
    padding: 15px 30px 15px 15px;
  }

  .footer-powered{
    text-align: center;

    .col-md-4{
      margin-bottom: 10px;
    }
  }

  .footer-powered {
    padding: 10px 0;
  }

  .footer-info {
    padding: 30px 0;
  }
  .category-item {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .product .gallery-thumbs {
    height: 340px;
  }

  .product-options .form-group {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .product-options .form-group:nth-child(2n+1) {
    padding-right: 0;
  }

  .product-maket {
    margin-top: 5px;
  }

  .product-download {
    margin-bottom: 0;
  }
  .product-tabs-img {
    margin-right: 5px;
  }


  .product-tabs .tab-content {
    padding: 0 10px;
  }


  .product-tabs .nav-tabs {
    margin-bottom: 20px;
  }
  .modules-product-swiper-button .swiper-button-next,
  .modules-product-swiper-button .swiper-button-prev{
    display: none;
  }
  .product-tabs .nav-tabs a:after {
    content: none;
  }

  .product-tabs .nav-tabs {
    font-size: 18px;
  }
  .account .simpleregister .form-horizontal .form-group {
    flex: 0 0 100%;
    max-width: 100%;
  }

  //767
}


@media all and (max-width: 575.5px) {
  h2{
    font-size: 24px;
  }
  h3{
    font-size: 22px;
  }
  .swiper-home-title {
    font-size: 30px;
  }
  .swiper-home .btn {
    padding: 10px 7px;
  }

  .about-image{
    display: none;
  }

  .btn {
    padding: 18px 5px!important;
    width: 100%;
    text-align: center;
  }

  .category-item {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .title {
    font-size: 26px;
  }

  .product-quick {
    margin-left: 0;
    margin-top: 15px;
  }

  .news-more{
    max-width: 200px;
  }

  .news-text {
    padding: 0 15px 15px;
  }

  .products-block {
    padding: 20px 15px 20px;
  }

  .product .gallery-thumbs {
   display: none;
  }

  .product-gallery-show {
    display: none;
  }

  .product-faq {
    padding: 15px;
  }

  .cart-products-img{
    text-align: center;
  }
  .cart-products-block {
    padding: 10px;
  }
  .cart-products-info {
    padding-left: 0;
  }

  .cart .simplecheckout-cart-total-title {
    padding-left: 10px;
  }

  .cart-products-qty button.btn{
    padding: 10px 5px!important;
  }

  .cart #simplecheckout_customer .form-group {
    flex: 0 0 100%;
    min-width: 100%;

    .col-sm-12{
      padding: 0;
    }
  }

  .product-tabs{

    li{
      width: 100%;
      margin-bottom: 10px;
    }

    a {
      padding: 0 0!important;
    }
  }
  .swiper-home.swiper-viewport {
    margin-left: -15px;
    margin-right: -15px;
  }


  body,html{
    overflow-x: hidden;
  }

  .feedback {
    margin-bottom: -15px;
  }
  #feedback-errors{
    margin-right: 15px;
  }

  .header-download{
    display: none;
  }
  .swiper-home-item {
    width: 100%;
    height: auto;
    padding: 70px 0;
    min-height: 200px;
  }

  .popular .nav-tabs .nav-item {
    margin-bottom: 5px;
    width: 100%;
  }

  .product-tabs li {
    background: #f3f3f3;
    text-align: center;
  }

  #logo {
    width: 100px;
    margin-left: 7px;
  }

  .mm-navbars_top{
    background: $white;

    #logo {
      width: 200px;
      margin: auto;
      padding: 15px 0;
    }
  }








  //575
}

@media all and (max-width: 450px) {
  //450
}

@media all and (max-width: 350px) {

  .title {
    font-size: 28px;
  }


  //350
}

.news{
  margin-bottom: 50px;

  .title{
    margin-bottom: 30px;

  }
  &-image{
    position: relative;
    margin-bottom: 30px;

    &:after{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba($black, .6);
      left: 0;
      top: 0;
      z-index: 1;
      transition: background-color .3s;
    }

    &:hover{

      &:after{
        background: rgba($black, .8);
      }

      .news-more{
        visibility:inherit;
        opacity: 1;
      }

      .news-text{
        bottom: 25%;
      }
    }
  }

  &-text{
    color: $white;
    position: absolute;
    bottom: 0;
    z-index: 2;
    padding: 0 30px 30px;
    transition: bottom .3s;
  }

  &-more{
    padding: 16px 37px;
    background: $orange;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s .2s;
  }

  &-title{
    height: 56px;
    margin-bottom: 20px;
    overflow: hidden;
    font-weight: 700;
    a{
      color: $white;
    }
  }

  &-date{
    font-size: 14px;
    margin-bottom: 20px;

    span{
      border-bottom: solid 2px $orange;
    }
  }

  &-href{
    padding: 18px 55px;
    margin-top: 20px;
  }

  &-container{
    background: $white;
    box-shadow: $shadow;
    padding: 30px ;

    img{
      max-width: 100%;
      height: auto!important;
    }
  }
}
.header{

  &-download{
    background: $lorange;
    font-size: 13px;

    a{
      text-decoration: underline;
      padding: 8px 0;
      display: block;
    }
  }

  &-top{
    padding: 30px 0;
    background-color: $white;

    ul{
      list-style: none;
      margin-bottom: 0;

      li{
        padding-left: 40px;
        white-space: nowrap;

        &:first-child{
          padding-left: 0;
        }
      }
    }

    .header-cart{
      border-right: none;
      justify-content: flex-end;
      min-width: 90px;
      width: auto;
      margin-left: 15px;
    }

    button{
      border: none;
      background: transparent;
    }

    .dropdown{
      list-style: none;
      position: relative;

      &:after{
        content: "\221F";
        font-size: 12px;
        transform:rotate(-45deg);
        position: absolute;
        right: -15px;
        top: 1px;
        transition: all .1s;
      }

      &.show{

        >a{
          color: $orange;
        }

        &:after{
          top: 3px;
          transform:rotate(135deg);
          color: $orange;
        }
      }
    }

    .dropdown-menu{
      box-shadow: $wshadow;
      padding: 15px 30px;
      left: -30px!important;
      border-radius: 0;
      border: none;
      min-width: 15rem;
    }

    &-callback{
      padding: 13px 15px;
    }
  }

  &-phones{
    padding-right: 15px;

    .dropdown{
      text-align: right;
    }

    .dropdown-menu{
      text-align: center;
      box-shadow: 0 4px 20px rgba(0,0,0,.25);

      li{
        padding-left: 0;
        text-align: left;
      }

      svg{
        width: 20px;
        height: 20px;
        margin-left: -20px;
      }
    }

   ul{

     li{
       padding-left: 0;


     }
   }

    .viber{
      font-size: 22px;
      font-weight: $semi;

      svg{
        width: 30px;
        height: 30px;
      }
    }

    .dropdown{

      &:after{
        right: -18px;
        top: 2px;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .dropdown-menu{
      left: 0!important;
      min-width: 12rem;
    }
  }

  &-email{
    margin-left: -5px;

    svg{
      width: 20px;
      height: 20px;
      fill: $orange;
      margin-right: 10px;
    }

  }



  &-menu{
    list-style: none;
    position: relative;
    z-index: 10000;


    &:hover{

      .header-menu-title{
        &:after{
          transform:rotate(-45deg);
        }
      }

      .header-menu-body{
        display: block;
      }
    }

    &-title{
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 30px;
      cursor: pointer;
      position: relative;
      background: $orange;

      &.active{

        &:after{
          transform:rotate(-45deg);
        }
      }

      &:after{
        content: "\221F";
        font-size: 12px;
        transform:rotate(-135deg);
        position: absolute;
        right: 20px;
        transition: all .1s;
      }


      svg{
        width: 20px;
        height: 20px;
        margin-right: 15px;
      }
    }

    &-body{
      margin-bottom: 0;
      list-style: none;
      background: $white;
      padding-left: 0;
      position: absolute;
      display: none;
      width: 100%;

      &.active{
        display: block;
      }

      .child{
        display: none;
        //display: flex;
        flex-wrap: wrap;
        position: absolute;
        right: calc(-300% - 90px);
        top: 0;
        padding-left: 0;
        width: calc(300% + 90px);
        list-style: none;
        height: 100%;

        li{
          padding: 10px 30px;
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
          background: $lgrey;

          a{
            display: block;
            height: 100%;
          }

          &:nth-child(3n+2){
            background: $white;
          }
        }
      }

      >li{
        padding: 15px 30px;

        &:hover{
          background: $lgrey;

          img{
            margin-right: 15px;
          }
          >a{

            &:after{
              right: 5px;
            }
          }

          .child{
            display: flex;
          }
        }
      }


      img{
        width: 20px;
        height: 20px;
        margin-right: 10px;
        transition: margin-right .3s;
      }

      .has-child{

        >a{
          position: relative;

          &:after{
            content: "\221F";
            font-size: 12px;
            transform:rotate(-135deg);
            position: absolute;
            right: -10px;
            transition: all .1s;
          }
        }
      }
    }
  }


  &-search{
    position: relative;
    margin-top: 10px;

    &:after{
      content: '';
      width: 0;
      height: 2px;
      background: $orange;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: width .3s;
    }

    &:hover{

      &:after{
        width: 100%;
      }

      svg{
        fill:$orange;
      }
    }

    input{
      width: 100%;
      border: none;
      border-bottom: solid 1px $mgrey;
      background: transparent;
      padding-bottom: 5px;

      &::placeholder{
        color: $black;
      }

    }

    &-btn{
      position: absolute;
      border: none;
      background: transparent;
      right: 0;
      top: -2px;


      svg{
        width: 20px;
        height: 20px;
        transition: fill .3s;
      }
    }
  }

  &-bottom{
    border-bottom:  1px solid $mgrey;
    border-top:  1px solid $mgrey;

    button{
      border: none;
      background: transparent;
    }

    &-block{
      height: 80px;
    }
  }


  &-lang{
    border-left: 1px solid $mgrey;
    border-right: 1px solid $mgrey;
    position: relative;

    button{
      padding: 0;
    }

    img{
      width: 20px;
    }

    .dropdown-menu.show{
      transform: translate3d(0, 0, 0)!important;
      top: 100%!important;
      min-width: 100%;
      border-radius: 0;
      border: none;
      text-align: center;
      padding: 0 0;
      box-shadow: $wshadow;
    }

    .show{

      .language-selected{
        background: $white;
      }
    }

    .language-select,.language-selected{
      padding: 28px 30px;
    }
  }

  &-account{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    border-right: 1px solid $mgrey;
    svg{
      width: 20px;
      height: 25px;
    }

    button{
      padding: 28px 30px;

      &.show{
        background: $white;
      }
    }

    .dropdown-menu.show{
      transform: translate3d(0, 0, 0)!important;
      top: 100%!important;
      min-width: 10rem;
      border-radius: 0;
      border: none;
      padding: 15px 30px;
      margin-left: 7px;
      box-shadow: 0 4px 20px rgba(0,0,0,.25);
    }
  }


  &-cart{
    height: 100%;
    width: 100%;
    border-right: 1px solid $mgrey;
    justify-content: center;
    align-items: center;
    display: flex;

    &-continue,&-checkout{
      margin-bottom: 0;

      a{
        display: block;
        padding: 10px 0;
        font-weight: bold;
      }

      strong{
        display: block;
        padding: 10px 0;
        cursor: pointer;
        background-color: rgba(0,0,0,.05);
      }

      &:hover{
        background: $orange;
      }
    }

    &-empty{

      svg{
        width: 20px;
        height: 20px;
      }
    }

    button{
      position: relative;
    }


    &-count{
      font-size: 10px;
      font-weight: bold;
      height: 18px;
      width: 18px;
      background: $orange;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -8px;
      margin-left: -5px;
      border-radius: 50%;
      position: relative;
      z-index: 2;
    }

    &-price{
      font-size: 18px;
      font-weight: bold;

      span{
        font-size: 14px;
      }
    }

    .dropdown-menu.show{
      transform: translate3d(0, 0, 0)!important;
      top: 100%!important;
      min-width: 10rem;
      border-radius: 0;
      border: none;
      box-shadow: 0 4px 20px rgba(0,0,0,.25);
      left: -50px!important;
      padding-bottom: 0;
      padding-top: 0;

      &.cart-empty{
        left: 205px!important;
        padding: 20px;

        svg{
          margin-right: 5px;
        }
      }
    }


    .table{
      margin-bottom: 0;

    }

    &-total{
      position: relative;

      &:before{
        content: url("../image/sprites.svg#shopping-cart");
        width: 25px;
        height: 25px;
        position: absolute;
        left: -20px;
        top: 0;
        z-index: 1;
      }
    }
  }

  .dropdown{

    span{
      cursor: pointer;
    }
  }

  .header-top .dropdown-menu#servicesDropdown{
    left: 35px!important;
    box-shadow: 0 4px 20px rgba(0,0,0,.25);
  }

  &-informations{

    .dropdown{
      width: 120px;

      &:after{
        right: 5px;
        cursor: pointer;
      }

      li{
        padding-left: 0;
      }
    }
  }

}

#logo{

  img{
    width: 230px;
  }
}























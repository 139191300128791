.advantages{

  margin-bottom: 50px;

  &-block{
    background: $white;
    box-shadow: $wshadow;
    height: 100%;
    padding: 45px;
    border-right: solid 1px $mgrey;
  }

  &-image{
    margin-bottom: 10px;
  }

  &-name{
    font-weight: bold;
  }

  &-count{
    font-size: 30px;
    font-weight: 700;
    color: $mgrey;
  }

}
.about{
  background: $white;
  box-shadow: $shadow;
  padding: 80px 50px;
  margin-top: -60px;
  margin-bottom: 90px;

  &-content{
    height: 300px;
    overflow: hidden;
    margin-bottom: 30px;

    &.active{
      height: 100%;
    }
  }

  .title{
    margin-bottom: 30px;
  }

  .btn{
    padding: 18px 83px;
  }

  &-text{
    margin-bottom: 30px;
  }
}
.account{
  padding-bottom: 60px;

  input{
    display: inline-block;
    border: 1px solid #e7e7e7;
    padding: 10px 20px;
  }

  &-login{

    input{
      margin-bottom: 10px;
      width: 100%;
    }

    a{
      font-size: 14px;
      font-weight: bold;
    }
  }

  ul{
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    a{
      text-decoration: none;
    }
  }

  label{
    width: 100%;
    margin-bottom: 0;
  }

  .well{
    padding: 30px;
    background: $white;
    box-shadow: $shadow;
    height: 100%;

    a{
      display: inline-block;
      font-weight: bold;

      &:not(.btn){
        &:hover{
          color: $orange;
        }
      }
    }
  }

  &-well{

    a{
      display: block!important;
      margin-bottom: 5px;
    }
  }

  &-register{

    .title{
      background: $white;
      box-shadow: 0 10px 10px 2px rgba(209,209,209,.4);
      margin: 0 5px 0;
      padding: 30px 0 0 30px;
    }

    .simple-content.account{
      background: $white;
      box-shadow: $shadow;
      padding: 30px;
    }
  }

  .simpleregister-button-right{
    float: none;
  }

  .simpleregister{

    .form-horizontal{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .form-group{
        flex: 0 0 51%;
        max-width: 51%;
      }
    }

    .simpleregister-button-right{
      text-align: center;
    }
  }

  .simpleregister-button-block{
    background: transparent;
  }

  .btn{
    @extend .btn-orange;
    padding: 15px 30px;
    background: $white;
  }

}
.feedback{

  background: $orange;
  padding: 80px 0 150px;
  margin-bottom: -70px;

  label{
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-left: 10px;
  }

  p{
    margin-bottom: 0;
  }

  input{
    height: 65px;
    margin-right: 10px;
    border-color: transparent;
    width: 260px;
    padding-left: 20px;
    border-width: 1px;

    &:active,&:focus{
      border-color: $black;
    }

  }

  .btn-black{
    padding: 20px 75px;
  }

  #feedback-errors{
    position: absolute;
  }

  &-title{
    font-size: 36px;
    font-weight: 900;
  }

  &-text{
    font-size: 18px;
    font-weight: bold;
  }

  &-form{
    margin-left: 40px;
  }

  &-download{

    button{
      background: none;
      border: none;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      margin-top: 33px;
      width: 125px;
    }

    img{
      margin-right: 10px;
    }
  }




}
.modules{
  margin-bottom: 50px;

  &-product{
    margin-bottom: 50px;
    .swiper-container {
      padding: 30px 0;
      margin-bottom: -20px;
    }

    &-swiper{

      &-button{
        position: relative;

        .swiper-button-prev{
          left: auto;
          right: 80px;
        }
        .swiper-button-next, .swiper-button-prev{
          width: 65px;
          height: 65px;
          border: solid 1px $mgrey;
          color: $black;
          transition: background-color .3s,border-color .3s;
          top: 16px;
          &:focus{
            outline: none;
          }

          &:hover{
            border: solid 1px $orange;
            background: $orange;
          }
        }

        .swiper-button-next:after, .swiper-button-prev:after {
          font-size: 14px;
        }
      }
    }
  }

}
.products{

  .col-xl-3,.col-xxl-3{
    margin-bottom: 30px;
  }

  &-swiper{

    .swiper-slide{
      height: auto;
    }

    .swiper-wrapper{
      box-sizing: inherit;
    }
  }

  &-block{
    background: $white;
    height: 100%;
    border: solid 1px $mgrey;
    padding: 20px 30px 20px;

    &:hover{
      box-shadow: $shadow;
    }
  }

  &-img{
    text-align: center;
    margin-bottom: 15px;
    max-width: 100%;
    height: auto;
  }

  &-name{
    font-weight: bold;
    margin-bottom: 5px;
  }

  &-code{
    font-size: 14px;
    color: $grey;
    margin-bottom: 15px;
  }

  &-price{
    font-size: 30px;
    font-weight: bold;

    span{
      font-size: 16px;
    }
  }

  &-options{
    height: 50px;
    width: 120px;
    padding-left: 20px;
    font-size: 14px;
    border-color: $grey;

    option{
      padding: 5px 0;
    }
  }


  &-info{
    margin-bottom: 20px;
  }

  .btn{
    width: 100%;
    text-align: center;
    padding: 18px 0;
  }
}
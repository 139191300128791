.catalog{
  background: $white;
  padding: 90px 50px;
  box-shadow: $wshadow;
  margin-bottom: 50px;

  &-container{
    max-height: 750px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &-block{
    margin-bottom: 5px;
  }

  &-title{
    margin-bottom: 15px;
  }

  &-letter{
    height: 30px;
    width: 30px;
    font-weight: bold;
    background: $orange;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  &-name{
    font-weight: bold;

    &:first-letter{
      color: $orange;
    }
  }
}
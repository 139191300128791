.product{

  .swiper-container-product {

    height: 200px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }
  .swiper-slide {
    background-size: cover;
    background-position: center;

    height: 200px;
  }
  .gallery-top {

    img{
      display: block;
    }
    .swiper-slide{
      height: 100%;
    }
  }

  .gallery-thumbs {
    height: 465px;
    overflow: hidden;
    position: relative;
    margin-right: 20px;
    flex: 0 0 100px;

    .swiper-slide{
      width: 100px;
    }

  }
  .gallery-thumbs .swiper-slide {
    max-height: 100px ;
    opacity: .4;
    max-width: 100px ;
    background-size: 70%;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all .1s ease;
    &:hover{
      opacity: 1;
    }
  }
  .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
  }

  &-images{
    max-height: 515px;
  }

  &-block{
    background: $white;
    padding: 30px;
    margin-bottom: 90px;
    box-shadow: $shadow;

    .swiper-button-next-thumbs,.swiper-button-prev-thumbs{
      outline: none;
      top: 95%;
      color: $grey;

      &:after{
        font-size: 20px;
      }
    }

    .swiper-button-next-thumbs{
     left: 90px;
    }
  }

  &-gallery{
    position: relative;

    &-show{
      width: 63px;
      height: 63px;
      background:rgba($white,.5);
      border: solid $white ;
      position: absolute;
      right: 25px;
      bottom: 45px;
      z-index: 8;
      transition: background-color .3s;

      &:hover{
        background:rgba($white,1);

        svg{
          fill: $orange;
        }
      }

      svg{
        width: 25px;
        height: 25px;
        transition: fill .3s;
      }
    }
  }

  &-title{
    font-size: 26px;
  }

  &-code{
    color: $grey;
    margin-bottom: 15px;
  }

  &-except{
    font-size: 14px;
    margin-bottom: 15px;
  }

  &-options{

    label{
      width: 100%;
      margin-bottom: 0;
    }

    input{
      width: 100%;
      padding: 11px 15px;
      background: $lgrey;
      border: solid 1px $sgrey;
    }

    select{
      width: 100%;
      padding: 11px 15px;
      background: $lgrey;
      border: solid 1px $sgrey;
      font-size: 14px;
    }

    .btn{
      padding: 13px 32px;
      background: $white;

      &:hover{
        background: $orange;
      }
    }

    .form-group{
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 10px;

      &:nth-child(2n + 1){
        padding-right: 40px;
      }
    }
  }

  &-attribute{
    margin-bottom: 15px;
    margin-top: 15px;

    #attribute-fields{
      display: none;
    }

    &-btn{
      font-weight: bold;
      cursor: pointer;
      text-decoration: underline;
      color: $grey;
      transition: color .3s;

      &:hover{
        color: $black;

        img{
          transform: rotate(720deg);
        }
      }

      span{
        margin-left: 5px;
      }

      img{
        margin-top: -5px;
        transition: transform .5s;
      }
    }

    &-list{
      padding-left: 20px;
      list-style: square;
      margin-bottom: 0;
      color: $orange;

      span{
        color: $grey;
      }
    }
  }

  &-maket{
    padding-left: 0;
    margin-bottom: 0;
    color: $grey;
    list-style: none;

    li{
      margin-bottom: 5px;
    }

    a{
      font-weight: bold;
      cursor: pointer;
      text-decoration: underline;
      margin-bottom: 10px;
      color: $grey;

      span{
        margin-left: 5px;
      }

      img{
        margin-top: -5px;
        transition: margin-top .3s;
      }

      &:hover{
        color: $black;

        img{
          margin-top: 0;
        }
      }
    }
  }

  &-download{
    margin-bottom: 15px;
  }

  &-price{
    font-size: 36px;
    font-weight: bold;

    span{
      font-size: 18px;
    }
  }

  &-cart{
    background: $white;
    padding: 18px 50px;
    height: 61px;
    svg{
      width: 20px;
      height: 20px;
    }
  }

  &-quick{
    padding: 18px 40px;
    height: 61px;
    margin-left: 5px;
  }

  &-top{
    margin-bottom: 90px;
  }

  &-tabs{

    .nav-tabs{
      font-size: 24px;
      display: flex;
      justify-content: space-around;
      margin-bottom: 40px;

      a{
        position: relative;
        padding: 0 30px;

        &:after{
          content: '';
          position: absolute;
          height: 2px;
          width: 0;
          left: 0;
          background: $orange;
          bottom: -5px;
          transition: width .5s;
        }

        &.active{
          font-weight: bold;

          &:after{
            width: 100%;
          }

        }
      }
    }

    .tab-content{
      padding: 0 100px;
    }

    &-title{
      font-weight: bold;
    }

    &-block{
      margin-bottom:30px;
    }

    &-img{
      flex: 0 0 50px;
      min-width: 50px;
      text-align: center;
      margin-right: 20px;
    }
  }

  &-faq{
    background: $white;
    box-shadow: $shadow;
    padding: 70px 50px 90px;
    margin-bottom: 60px;

    .title{
      margin-bottom: 30px;
    }

    .faqs-accordion-item{
      background: $lgrey;
      border: solid 1px $sgrey;
      transition: background-color .3s;

      &.active{
        background: $white;
        box-shadow: $shadow;
      }
    }
  }

  &-related{
    padding-bottom: 30px;
  }

  &-reviews{
    margin-bottom: 60px;


    input{
      height: 65px;
      margin-right: 10px;
      padding-left: 20px;
      width: 100%;
      border: solid 1px $orange;
    }

    textarea{
      margin-right: 10px;
      padding-left: 20px;
      width: 100%;
      border: solid 1px $orange;
    }

    label{
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 0;
      margin-left: 10px;
      width: 100%;
    }

    #reviews-error{
      margin-top: 25px;
    }

    .btn{
      padding: 20px 64px;
      background: $white;
      &:hover{
        background: $orange;
        box-shadow: $oshadow;
      }
    }

    .help-block{
      font-size: 12px;
    }

    &-name{
      margin-bottom: 20px;
    }

    &-form{
      background: $white;
      box-shadow: $shadow;
      padding: 30px;
      margin-top: 50px;
      display: none;
    }

    &-item{
      @extend .faqs-reviews-item;
      background: $white;
      height: 100%;
    }
    &-author{
      font-weight: bold;
    }

    &-date{
      color: $grey;
      margin-bottom: 25px;
    }

    &-rating{

      svg{
        width: 15px;
        height: 15px;
        margin-right: 2px;
        fill: $grey;
      }

      &-active{
        fill: $orange!important;
      }
    }

    &-rating{

      &-stars{
        width: 151px;
      }

      &-enter{
        margin: 15px 0 30px;

        &:after{
          content: "";
          clear: both;
          display: table;
        }

        svg{
          height: 25px;
          width: 25px;
        }


        label{
          margin-bottom: 0;
          margin-top: 4px;
        }

        i{
          width: 30px;
          height: 30px;
          display: inline-block;
          margin-left: -30px;
          position: relative;
          z-index: 1;
          float: right;
          margin-right: -30px;
        }

        input{
          opacity: 0;
          width: 30px;
          height: 30px;
          position: relative;
          z-index: 10;
          cursor: pointer;
          float: right;
          margin-right: 0;
          padding-right: 5px;


          +i{
            background: url(../image/sprites.svg#star-or-o) no-repeat center;
          }

          &:hover{
            ~ i{
              background: url(../image/sprites.svg#star-or) no-repeat center;
            }
          }

          &:checked{
            ~i{
              background: url(../image/sprites.svg#star-or) no-repeat center;
            }
          }
        }
      }
    }
  }

  &-nofaq{
    margin-bottom: 90px;
  }
}
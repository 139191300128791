.cart{
  margin-bottom: 30px;

  textarea{
    resize: none;
  }

  input,textarea{
    width: 100%;
    padding: 10px 20px;
    &:not([type="submit"]){
      border: 1px solid $sgrey;
    }

    &:active,&:focus{
      border: 1px solid $sgrey;
      outline: none;
    }
  }

  select{
    width: 100%;
    padding: 15px 15px;
    background: $white;
    border: solid 1px $sgrey;
  }

  button{
    outline: none;

    &:hover,&:focus,&:active{
      outline: none;
    }
  }

  legend{
    font-weight: 600;
  }

  label{
    margin-bottom: 0;
    font-size: 16px;
  }

  #simplecheckout_cart{
    background: $white;
    box-shadow: $shadow;
  }

  .simple-content .checkout-heading{
    margin-bottom: 15px;
    background: $white;
    box-shadow: $shadow;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px 15px;

    a{
      text-decoration: underline;
    }
  }

  .form-group{
    margin-bottom: 15px;
  }

  .radio{
    position: relative;
    margin-right: 15px;

    label{
      margin-left: 20px;
      cursor: pointer;

      &:before{
        content: '';
        width: 15px;
        height: 15px;
        background: $white;
        border: 2px solid $orange;
        position: absolute;
        left: 0;
        border-radius: 50%;
        top: 5px;
      }
    }
    input{
      width: 0;

      &:checked{

        ~span{
          &:before{
            content: '';
            width: 15px;
            height: 15px;
            background: $white;
            border: 2px solid $orange;
            position: absolute;
            left: 0;
            border-radius: 50%;
            top: 5px;
            background: $orange;
          }
        }
      }
    }
  }

  #simplecheckout_customer{

    .form-horizontal{
      display: flex;
      flex-wrap: wrap;
    }



    .form-group{
      flex:0 0 50%;
      min-width: 50%;

      &.row-customer_register{
        flex:0 0 100%;
        min-width: 100%;
      }

      .col-sm-8,.col-sm-4{
        flex:0 0 100%;
        min-width: 100%;
      }
    }
  }

  #simplecheckout_shipping_address{
    .col-sm-8,.col-sm-4{
      flex:0 0 100%;
      min-width: 100%;
    }
  }


  .simplecheckout-button-block{
    background: transparent;
  }

  #simplecheckout_button_confirm{
    @extend .btn-orange;
    padding: 21px 56px;
  }


  &-products{
    background: $white;
    box-shadow: $shadow;

    &-block{
      padding: 15px;
      border-bottom: solid 1px $sgrey;
    }

    &-img{
      flex: 0 0 115px;
    }

    &-info{
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      padding-left: 30px;

      .btn-danger{
        width: 35px;
        height: 35px;
        border: $sgrey;
        background: $white;
        border: solid 1px $sgrey;
        position: relative;
        padding: 0;
        transition: border-color .3s;

        &:hover{
          border: solid 1px $grey;

          &:before, &:after{
            background-color: $grey;
          }
        }

        &:before, &:after{
          content: '';
          position: absolute;
          height: 15px;
          width: 2px;
          background-color: $sgrey;
          transition: background-color .3s;
          top: 10px;
        }

        &:after{
          transform: rotate(45deg);
        }
        &:before{
          transform: rotate(135deg);
        }


      }
    }

    &-name{
      font-weight: bold;
      margin-bottom: 5px;
    }

    &-code{
      color: $grey;
      font-size: 14px;
      margin-bottom: 5px;
    }

    &-options{
      margin-bottom: 10px;
    }

    &-price{
      font-weight: bold;
      font-size: 18px;
      color: $orange;

      span{
        font-size: 14px;
      }
    }

    &-qty{

      button{
        border-radius:50%;
        height: 25px;
        width: 25px;
        border: solid 2px $orange;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        font-weight: 900;
        color: $orange;
        margin: 0 5px;
      }

      input{
        padding: 0 5px;
        border: solid 1px $orange!important;
      }
    }

    &-total{
      font-weight: bold;
    }
  }

  .simplecheckout-cart-total{
    background: $white;

    &-title{
      padding-left: 30px;
    }
  }

  #total_total{
    font-size: 24px;

    .simplecheckout-cart-total-value{
      font-size: 30px;
      font-weight: bold;
      color: $orange;

      span{
        font-size: 24px;
      }
    }
  }

}
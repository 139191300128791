.information{
  margin-bottom: 60px;

  iframe{
    width: 100%;
  }

  .btn{
    padding: 13px 32px;
    background: $white;

    &:hover{
      background: $orange;
    }
  }

  label{
    margin-bottom: 0;
  }


  input,textarea{
   &:not(.btn){
     border: 1px solid #e7e7e7;
     width: 100%;
     padding: 10px 20px;
     margin-bottom: 15px;
   }
  }

  &-container{
    background: $white;
    box-shadow: $shadow;
    padding: 30px;

    .panel-body{

      img{
        width: 25px;
        height: 25px;
        margin-right: 5px;
      }
    }
  }

  address{
    margin-bottom: 0;
  }



}
.popular{
  margin-bottom: 50px;

  .title{
    line-height: 36px;
    margin-bottom: 40px;
  }

  .nav-tabs{
    margin-bottom: 20px;
    border: none;
    font-size: 16px;
    font-weight: bold;

    .nav-item{
      margin-bottom: 5px;
    }
  }

  .nav-link {
    padding: 1rem 1rem;
  }

  .nav-tabs .nav-link.active {
    color: $black;
    background-color: $orange;
    border-color: $orange;
  }

  .nav-tabs .nav-link {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: $mgrey;
    border-width: 2px;
    margin-right: 5px;
    transition: border-left-color .25s,border-top-color .25s .1s,border-right-color .25s .2s,border-bottom-color .25s .3s;

    &:hover{
      border-color: $orange;
    }
  }

  &-href{
    margin-top: 15px;

    .btn{
      padding: 18px 55px;
    }
  }

}
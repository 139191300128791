.footer{
  background: $black;
  color: $fgrey;

  a{
    color: $fgrey;
  }

  .btn{
    border: solid 2px $fgrey;
    color: $white;
    padding: 18px 90px;
    transition:color .3s,border-color .3s;
    margin-top: 20px;

    &:hover{
      border-color: $orange;
      color: $orange;
    }

    &-orange{
      border-color: $orange;

      &:hover{
        color: $black;
        box-shadow: $oshadow;
      }
    }
  }

  &-info{
    padding: 60px 0;
  }

  &-logo{
    margin-bottom: 40px;
  }

  &-title{
    font-weight: bold;
    font-size: 14px;
    color: $white;
    margin-bottom: 15px;
  }

  &-list{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li{
      margin-bottom: 10px;
    }
  }

  &-svg{

    svg{
      fill: $white;
      height: 15px;
      width: 15px;
      margin-top: -3px;
      margin-right: 5px;
    }
  }

  &-powered{
    border-top:solid 1px $fgrey;
    padding: 30px 0;

    &-social{
      border: solid 1px $white;
      margin: 0 5px;
      transition: background-color .3s;

      a{
        padding: 22px;
      }

      svg{
        width: 20px;
        height: 20px;
        fill:$white;
      }

      &:hover{
        background: $orange;

        svg{
          fill:$black;
        }
      }
    }

    &-cart{
      margin-left: 10px;

      img{
        width: 80px;
        height: 35px;
      }
    }
  }
}
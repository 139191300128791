body,html{
  font-family: 'Montserrat', sans-serif;
  color: $black;
  background: $lgrey;
  font-size: 16px;
}

a{
  color: $black;
  transition: color .3s;

  &:hover{
    color: $black;
    text-decoration: none;
  }
}
#button-confirm{
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  border: 2px solid $orange;
  transition: background-color .3s;
  background: transparent;
  &:hover{
    background: $orange;
    box-shadow: $oshadow;
  }
}
.btn{
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;

  &-orange,.btn-primary{
    border: 2px solid $orange;
    transition: background-color .3s;

    &:hover{
      background: $orange;
      box-shadow: $oshadow;
    }
  }

  &-black{
    border: 2px solid $black;
    transition: background-color .3s;
    background: transparent;

    &:hover{
      background: $black;
      color: $orange;
    }
  }
}

#menu-layout{
  z-index: 9999;
  position: absolute;
  transition: background .5s;

  &.active{
    width: 100%;
    height: 100%;
    background: rgba($black,.5);
  }
}

input,button,span,select{
  &:focus,&:active,&:hover{
    outline: none;
  }
}

.title{
  font-size: 36px;
  font-weight: 900;
}

.alert{
  font-size: 18px;
  font-weight: bold;

  &-danger{
    color: red;
  }

  &-success{
    color: #00a046;
  }
}

.breadcrumb{
  display: flex;
  list-style: none;
  padding: 30px 0 20px;
  margin-bottom: 0;

  a{

    span{
      margin: 0 3px;
    }
  }

  &-current{
    font-weight: bold;
  }
}

.pagination{
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  justify-content: center;

  li{
    font-weight: bold;
    width: 30px;
    height: 30px;
    border:2px solid $orange;
    background: $orange;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;

    &.active{
      background: $white;
    }

    a{
      display: block;
      width: 100%;
      text-align: center;
    }


  }
}
.mm-slideout { z-index:auto;}

.modal{

  &-close{
    width: 35px;
    height: 35px;
    border: $sgrey;
    background: $white;
    border: solid 1px $sgrey;
    position: relative;
    padding: 0;
    transition: border-color .3s;
    cursor: pointer;

    &:hover{
      border: solid 1px $grey;

      &:before, &:after{
        background-color: $grey;
      }
    }

    &:before, &:after{
      content: '';
      position: absolute;
      height: 15px;
      width: 2px;
      background-color: $sgrey;
      transition: background-color .3s;
      top: 10px;
      left: 15px;
    }

    &:after{
      transform: rotate(45deg);
    }
    &:before{
      transform: rotate(135deg);
    }
  }

  .modal-header {
    align-items: center;
    font-size: 18px;
  }

  input,textarea{
    padding: 10px 20px;
    border: 1px solid $sgrey;
    margin-bottom: 10px;
    width: 100%;
  }
  .btn-orange{
    padding: 13px 32px;
    background: $white;

    &:hover{
      background: $orange;
    }
  }

  .boc_product_field{

    .price{
      margin-bottom: 0;
      font-size: 30px;
      font-weight: 700;
      color: #ffae00;
      text-align: right;

      span{
        font-size: 24px;
      }
    }

    .name{
      font-size: 20px;
      margin-bottom: 10px;
    }



    .input{
      text-align: center;

    }


    .error{
      display: none;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .has-error{

      .error{
        display: block;
      }
    }
  }
}

.modal-open{

  .header-menu{
    z-index: 1000;
  }

}


#to-top{
  position: fixed;
  right: 30px;
  top: 80%;
  display: none;
  cursor: pointer;

  p{
    display: inline-block;
    opacity: .39;
    color: #999;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    position: absolute;
    top: 33px;
    right: -16px;
    margin-top: 5px;
  }
  i{
    color: $orange;
    font-size: 18px;
    margin-right: 2px;
  }


}